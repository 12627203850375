import React from 'react';

export default function Main() {
  return (
    <div>
      <div className="container background-white">
        <div className="row margin-vert-30">
          {/* Main Text */}
          <div className="col-md-12">
            <h2 className="text-center mb-4 display-4">Welcome to RambleNetworks</h2>
            
            <p className="text-center">
              Where your travel dreams take flight!<br />
              Nestled near NJP Railway Station, Jalpaiguri in Siliguri & 15 Kms from Bagdogra Airport, we're not just travel agents, we're architects of epic adventures.<br />
              Picture this: customized tours to Darjeeling, Sikkim, Dooars, Nepal, and Bhutan.<br />
              Spiced up with River Rafting, Toy Train Joyrides, Jungle Safaris, Night Escapades, Chopper Thrills, Paragliding Highs, and Trekking Escapades.
            </p>
            <p className="text-center">
              We're not about trips; we're about crafting stories – YOUR stories.<br />
              Get ready for a journey that's more than a vacation; it's a symphony of excitement.<br />
              Ramble Networks – Where adventure meets you at every turn!
            </p>

            {/* WhatsApp Contact Button */}
            <div className="text-center mt-4">
              <a href="https://wa.me/918509631121" target="_blank" rel="noopener noreferrer">
                <button className="button-9">Contact us</button>
              </a>
            </div>
            {/* End WhatsApp Contact Button */}

          </div>
          {/* End Main Text */}
        </div>
      </div>
    </div>
  );
}
